import { Serializer, Deserializer } from "jsonapi-serializer";
import { MAX_PATH_LENGTH } from "@app/constants/common";

export const SerializeData = (name: string, values: unknown) => {
  if (values instanceof Object) {
    const result = new Serializer(name, {
      attributes: Object.keys(values),
      keyForAttribute: "camelCase",
      pluralizeType: false,
    }).serialize(values);
    return result;
  }
};

export const DeserializeData = async (values: unknown) => {
  const deserializer = new Deserializer({
    keyForAttribute: "camelCase",
    pluralizeType: false,
  });

  const result = await deserializer.deserialize(values);

  return result;
};

export const cutString = (str: string, max: number = MAX_PATH_LENGTH) => {
  return str.length > max ? `${str.substring(0, max)}...` : str;
};
