import React, { useState } from "react";
import { Stage, Layer, Group, Image, Arrow } from "react-konva";
import GraphOperationItem from "@app/components/GraphOperationItem/GraphOperationItem";

import { getLinePath } from "@app/components/DiagramWrapper/DiagramWrapper.utils";
import useImage from "use-image";

import { IMAGE_TRASH_URL } from "@app/constants/common";

import styles from "./DiagramWrapper.module.scss";
import { LinkOperationType } from "@app/types/api.types";
import { ServiceSpecificationApiOperationLinkPositionEnum } from "@app/@generated";

interface DiagramProps {
  isEditable?: boolean;
  container: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes?: any;
  nodesUsed?: null | string[];
  operationsLinks?: null | LinkOperationType[];
  onDelete?: (id: string) => void;
  onDeleteLine?: (id: string) => void;
  onAddLine?: (
    point: Array<number>,
    startID: string,
    stopID: string,
    outwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum,
    inwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum
  ) => void;
  onChangePosition?: (id: string, x: number, y: number) => void;
  onSavePosition?: (id: string, x: number, y: number) => void;
}

export function DiagramWrapper({
  isEditable = false,
  container,
  nodes,
  nodesUsed,
  operationsLinks,
  onDelete,
  onDeleteLine,
  onAddLine,
  onChangePosition,
  onSavePosition,
}: DiagramProps) {
  const width = 220;
  const height = 42;
  const [startLineID, setStartLineID] = useState<null | string>(null);
  const [startLinePosition, setStartLinePosition] =
    useState<null | ServiceSpecificationApiOperationLinkPositionEnum>(null);

  const [drawingLine, setLinePoints] = useState<null | Array<number>>(null);
  const containerWrap = document.getElementById(container);
  const sceneWidth = containerWrap ? containerWrap.offsetWidth : 500;
  const [hoverID, setHoverID] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<null | Array<number>>(
    null
  );

  const sceneHeight =
    containerWrap && containerWrap.offsetHeight > 800
      ? containerWrap.offsetHeight
      : 800;

  const [imageBin] = useImage(IMAGE_TRASH_URL);

  const handleChangePosition = (
    id: string,
    xCoordinate: number,
    yCoordinate: number
  ) => {
    if (onChangePosition) onChangePosition(id, xCoordinate, yCoordinate);
  };

  const onStartDrawLine = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evt: any,
    startX?: number,
    startY?: number,
    startId?: string,
    position?: ServiceSpecificationApiOperationLinkPositionEnum
  ) => {
    if (startId) setStartLineID(startId);
    if (position) setStartLinePosition(position);

    setLinePoints([startX, startY, evt.evt.offsetX, evt.evt.offsetY]);
  };

  const onStopDrawLine = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evt: any,
    startX?: number,
    startY?: number,
    stopId?: string,
    position?: ServiceSpecificationApiOperationLinkPositionEnum
  ) => {
    if (drawingLine) {
      if (
        onAddLine &&
        startLineID &&
        stopId &&
        startLineID !== stopId &&
        startLinePosition &&
        position
      )
        onAddLine(
          [startX, startY, evt.evt.offsetX, evt.evt.offsetY],
          startLineID,
          stopId,
          startLinePosition,
          position
        );
      setLinePoints(null);
    }
    setStartLineID(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDrawLine = (evt: any) => {
    if (drawingLine) {
      setLinePoints([
        drawingLine[0],
        drawingLine[1],
        evt.evt.offsetX,
        evt.evt.offsetY,
      ]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDeleteLine = (link: any) => {
    if (onDeleteLine) onDeleteLine(link);
  };

  const handleHover = (id: string | null) => {
    setHoverID(id);
  };

  const handleTooltipView = React.useCallback(
    (event: MouseEvent) => {
      const parent = document.getElementById("wrap-api-operations");
      const top = parent?.offsetTop || 0;
      const left = parent?.offsetLeft || 0;
      const width = parent?.offsetWidth || 0;
      const height = parent?.offsetWidth || 0;

      if (nodes && hoverID) {
        const max =
          `${nodes[hoverID].httpMethod}${nodes[hoverID].path}`.length * 7;
        const x =
          width < event.offsetX + max
            ? left + event.offsetX - max
            : left + event.offsetX + 5;
        const y =
          height < event.offsetY + 20
            ? top + event.offsetY - 30
            : top + event.offsetY + 5;
        setTooltipPosition([x, y]);
      }
    },
    [hoverID]
  );

  React.useEffect(() => {
    if (hoverID) window.addEventListener("mousemove", handleTooltipView);
    else window.removeEventListener("mousemove", handleTooltipView);
    return () => window.removeEventListener("mousemove", handleTooltipView);
  }, [hoverID, handleTooltipView]);

  return (
    <>
      <Stage
        width={sceneWidth}
        height={sceneHeight}
        onMouseMove={onDrawLine}
        onMouseUp={onStopDrawLine}
      >
        <Layer>
          {nodes &&
            nodesUsed &&
            nodesUsed.length > 0 &&
            operationsLinks &&
            nodesUsed.map(item => {
              return (
                <GraphOperationItem
                  key={item}
                  {...nodes[item]}
                  id={item}
                  isEditable={isEditable}
                  onChangePosition={handleChangePosition}
                  onSavePosition={onSavePosition}
                  onDelete={onDelete}
                  isAvalableLines={nodesUsed.length > 1}
                  onStartDrawLine={onStartDrawLine}
                  onStopDrawLine={onStopDrawLine}
                  width={width}
                  height={height}
                  onHover={handleHover}
                />
              );
            })}
          {nodes &&
            operationsLinks &&
            operationsLinks.map(link => {
              const path = getLinePath(
                width,
                height,
                [
                  nodes[link.outwardApiOperation].xCoordinate,
                  nodes[link.outwardApiOperation].yCoordinate,
                  nodes[link.inwardApiOperation].xCoordinate,
                  nodes[link.inwardApiOperation].yCoordinate,
                ],
                10,
                link.outwardLinkPosition,
                link.inwardLinkPosition
              );
              return (
                <Group>
                  <Arrow
                    points={path}
                    pointerLength={8}
                    pointerWidth={8}
                    fill="black"
                    stroke="black"
                    strokeWidth={1}
                  />
                  {imageBin && isEditable && (
                    <Image
                      image={imageBin}
                      x={path[0] + 2}
                      y={path[1] + 2}
                      width={16}
                      height={16}
                      onClick={() => handleDeleteLine(link)}
                    />
                  )}
                </Group>
              );
            })}
          {drawingLine && (
            <Arrow
              points={drawingLine}
              pointerLength={8}
              pointerWidth={8}
              fill="black"
              stroke="black"
              strokeWidth={1}
            />
          )}
        </Layer>
      </Stage>
      {hoverID && nodes && !drawingLine && tooltipPosition && (
        <span
          className={styles.tooltip}
          style={{
            transform: `translate(${tooltipPosition[0]}px, ${tooltipPosition[1]}px)`,
          }}
        >
          <strong>{nodes[hoverID].httpMethod}</strong> {nodes[hoverID].path}
        </span>
      )}
    </>
  );
}
