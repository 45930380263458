import { ReactNode } from "react";
import { Col, Row, Typography } from "antd";

import ApiPreview from "@app/components/ApiPreview";

import Paragraph from "antd/lib/typography/Paragraph";

import styles from "./SpecificationPreview.module.scss";

type ApiPreviewPropsType = {
  isEditable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  defaultMode?: string;
  constrols?: ReactNode;
};

const SpecificationPreview = ({
  isEditable,
  data,
  onChange,
  defaultMode,
  constrols,
}: ApiPreviewPropsType) => {
  const { description, apiSpecification } = data.attributes;

  return (
    <div className="preview-api">
      <div>
        <Row justify="space-between">
          <Col xs={constrols ? 15 : 24}>
            <Typography.Title level={5}>Description:</Typography.Title>
            <Paragraph>{description}</Paragraph>
          </Col>
          <Col xs={9} className={styles.buttonGroup}>
            {constrols}
          </Col>
        </Row>
      </div>

      <div>
        <Typography.Title level={5}>API Specification:</Typography.Title>
      </div>

      <ApiPreview
        apiSpecification={apiSpecification}
        isEditable={isEditable}
        onChange={onChange}
        defaultMode={defaultMode}
      />
    </div>
  );
};

SpecificationPreview.defaultProps = {};

export default SpecificationPreview;
