import { ReactNode, Key } from "react";
import { MenuItemType } from "@app/components/Common/atoms/Sidebar/Sidebar.types";

export function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItemType[],
  type?: "group"
): MenuItemType {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItemType;
}
