import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import { useEffect, useState } from "react";

import { logger } from "@app/utils/logger";
import { AxiosResponse } from "axios";
import { DeserializeData } from "@app/utils/common";
import { ITool, Tools } from "@app/types/common.types";
import { servicesAPIToolsApi } from "@app/features/services/api/services.api";
import styles from "@app/features/services-specifications/components/ServiceSpecificationTools/ServiceSpecificationTools.module.scss";
import { ApiTool } from "@app/components/ApiTool";

import { useTranslation } from "react-i18next";
import EmptyState from "@app/components/EmptyState";
// import styles from "./ServiceTools.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceTools = (props: any) => {
  const { t } = useTranslation();

  const [tools, setTools] = useState<Tools | null>(null);

  const { id } = props.data;
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  const getTools = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await servicesAPIToolsApi.servicesServiceIdApiToolsGet({
          serviceId: id,
        });

      DeserializeData(data).then(res => {
        const tools: Tools = {};

        res.map((tool: ITool) => {
          tools[tool.category] = tools[tool.category]
            ? [...tools[tool.category], tool]
            : [tool];
        });
        setTools(tools);
      });
      // const links = deserializeOperationsLinks(data.data);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    getTools();
  }, [id]);

  return (
    <div id="wrap-api-operations">
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div>
          <div className="">
            {(!tools || (tools && Object.keys(tools).length === 0)) && (
              <EmptyState title="" text="There are no tools yet" />
            )}
            {tools &&
              Object.keys(tools).map(group => (
                <div className={styles.tools}>
                  <div className={styles.toolGroup}>
                    <h6 className={styles.toolGroupTitle}>
                      {t(`default.tools.${group}`)}
                    </h6>

                    {tools[group]?.map((tool: ITool) => (
                      <div className={styles.tool}>
                        <ApiTool {...tool} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceTools;
