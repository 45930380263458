/* eslint-disable */
class Logger {
  log(message: string): void {
    console.log(`[INFO] ${message}`);
  }

  error(error: unknown): void {
    console.error(error);
  }

  warn(message: string): void {
    console.warn(`[WARNING] ${message}`);
  }

  debug(message: string): void {
    console.debug(`[DEBUG] ${message}`);
  }
}

export const logger = new Logger();

// Example usage

// logger.log("This is an informational message.");
// logger.error("This is an error message.");
// logger.warn("This is a warning message.");
// logger.debug("This is a debug message.");

/* eslint-enable */
