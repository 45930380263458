export const SERVICE_SPECIFICATIONS_TRANSLATIONS_EN = {
  "serviceSpecificationsNavigation.title": "Service Specifications",
  "serviceSpecificationsScreen.title": "Service Specifications",
  "serviceSpecifications.title": "Service Specifications",
  "serviceSpecifications.addNew": "Add Service Specification",
  "serviceSpecificationsCreate.title": "Create Service Specification",
  "serviceSpecificationsEdit.title": "Edit Service Specification",
  "serviceSpecificationsAPIOperations.title": "API Journey",
  "serviceSpecificationsAPITools.title": "API Tools",
  "serviceSpecificationsAPIToolsAdd.title": "Add API Tools",
  "serviceSpecificationsAPIOperationsEdit.title": "Edit API Journey",
  "serviceSpecificationsAPIToolsEdit.title": "Edit API Tools",

  //API Operations
  "serviceSpecificationsAPIOperationsTools.title": "API Operations",
  "serviceSpecificationsAPIOperationsToolsAdd.title": "Add API Operations",
  "serviceSpecificationsAPIOperationsToolsEdit.title": "Edit API Operations",

  // Common
  "serviceSpecifications.create": "Create",
  "serviceSpecifications.edit": "Edit",
  "serviceSpecifications.delete": "Delete",
  "serviceSpecificationsApi.title": "API Specification",
  "serviceSpecificationsEvents.title": "API Events",
  "serviceSpecificationsEnvironments.title": "API Environments",
  // Delete
  "serviceSpecifications.deleteNotificationTitle": "Successfully deleted",
  "serviceSpecifications.deleteNotification":
    "Service Specification was successfully deleted",
  "serviceSpecifications.deleteErrorNotification":
    "Service Specification was not deleted",
};
