import { StarOutlined, BuildOutlined } from "@ant-design/icons";

import { RouteItemDef } from "@app/types/route.types";

import { ServicesPathsEnum } from "../constants/services.paths";
import Services from "@app/features/services/screens/Services/Services";
import FavoriteServices from "@app/features/services/screens/FavoriteServices";

export const SERVICES_ROOT_SCREEN: RouteItemDef = {
  id: "services",
  path: ServicesPathsEnum.SERVICES,
  title: "servicesNavigation.title",
  icon: BuildOutlined,
  component: Services,
  hideInNavigation: false,
  // public route available for all
};

export const SERVICES_FAVORITES_SCREEN: RouteItemDef = {
  id: "favorites",
  path: ServicesPathsEnum.FAVORITES,
  title: "favoritesScreen.title",
  icon: StarOutlined,
  component: FavoriteServices,
  hideInNavigation: false,
  // public route available for all
};

export const SERVICES_ROUTES_PRIVATE = [SERVICES_FAVORITES_SCREEN];
export const SERVICES_ROUTES_PUBLIC = [SERVICES_ROOT_SCREEN];

export const SERVICES_ROUTES = [
  SERVICES_ROOT_SCREEN,
  SERVICES_FAVORITES_SCREEN,
];
