import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getServices } from "@app/features/services/store/services.slice";
import { useAppDispatch, useAppSelector } from "@app/store/store";

import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";

import ServicesList from "@app/components/ServicesList";
import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";
import EmptyState from "@app/components/EmptyState";
import { SERVICES_ROOT_SCREEN } from "@app/features/services/routes/services.routes";
import ServiceEvents from "@app/features/services/components/ServiceEvents";
import ServiceEnvironments from "@app/features/services/components/ServiceEnvironments";
import ServiceApi from "@app/features/services/components/ServiceApi";
import ServiceSchema from "@app/features/services/components/ServiceSchema";
import { Panes } from "@app/types/common.types";

function ServicesScreen() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { services, isLoading } = useAppSelector(state => state.services);

  const allPanes: Panes = {
    api: {
      type: t("servicesApi.title"),
      panTitle: (title: string) => `${t("servicesApi.title")} - ${title}`,
      component: ServiceApi,
      closable: true,
    },
    events: {
      type: t("servicesEvents.title"),
      panTitle: (title: string) => `${t("servicesEvents.title")} - ${title}`,
      component: ServiceEvents,
      closable: true,
    },
    environments: {
      type: t("servicesEnvironments.title"),
      panTitle: (title: string) =>
        `${t("servicesEnvironments.title")} - ${title}`,
      component: ServiceEnvironments,
      closable: true,
    },
    schema: {
      type: t("servicesSchema.title"),
      panTitle: (title: string) => `${t("servicesSchema.title")} - ${title}`,
      component: ServiceSchema,
      closable: true,
    },
  };

  useEffect(() => {
    dispatch(getServices({ filterIsSubscribedByCurrentAccount: true }));
  }, [dispatch]);

  return (
    <ContentLayout
      noContentStyle
      header={{
        title: t("favoritesScreen.title"),
      }}
      title={t("favoritesScreen.title")}
    >
      {isLoading && <LoadingSpinner />}

      {!isLoading && services.length !== 0 && (
        <ServicesList
          title={t("favoritesScreen.title")}
          allPanes={allPanes}
          services={services}
        />
      )}
      {!isLoading && services.length === 0 && (
        <EmptyState
          text={
            <>
              To add APIs to Favorites, navigate to{" "}
              <a href={SERVICES_ROOT_SCREEN.path}>Catalog</a>, choose a Service,
              open its API view and click "Subscribe".
            </>
          }
        />
      )}
    </ContentLayout>
  );
}

export default ServicesScreen;
