import { createSlice } from "@reduxjs/toolkit";

export const TOKEN_SLICE_KEY = "token";

interface SliceState {
  isAuthenticated: boolean;
}

const initialState: SliceState = {
  isAuthenticated: false,
};

const tokenSlice = createSlice({
  name: TOKEN_SLICE_KEY,
  initialState,
  reducers: {
    setAuthenticated: state => {
      state.isAuthenticated = true;
    },
    setUnAuthenticated: state => {
      state.isAuthenticated = false;
    },
  },
});

export const { setAuthenticated, setUnAuthenticated } = tokenSlice.actions;
export const tokenReducer = tokenSlice.reducer;
