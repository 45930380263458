import {
  PublicServiceSpecificationsApi,
  PublicServiceSpecificationsAPIOperationsApi,
  PublicServiceSpecificationsAPIToolsApi,
  PublicServiceSpecificationsSubscriptionsApi,
} from "@app/@generated";
import { axiosInstance } from "@app/api/api";
import { apiClientConfiguration } from "@app/constants/api.constants";

const servicesApi = new PublicServiceSpecificationsApi(
  apiClientConfiguration,
  undefined,
  axiosInstance
);

const servicesSubscriptionsApi =
  new PublicServiceSpecificationsSubscriptionsApi(
    apiClientConfiguration,
    undefined,
    axiosInstance
  );

const servicesAPIToolsApi = new PublicServiceSpecificationsAPIToolsApi(
  apiClientConfiguration,
  undefined,
  axiosInstance
);

const servicesAPIOperationsApi =
  new PublicServiceSpecificationsAPIOperationsApi(
    apiClientConfiguration,
    undefined,
    axiosInstance
  );

export {
  servicesApi,
  servicesSubscriptionsApi,
  servicesAPIToolsApi,
  servicesAPIOperationsApi,
};
