import { COLORS_TYPE } from "@app/components/GraphOperationItem/GraphOperationItem.types";

export const COLORS = {
  PRIMARY: "#e0efff",
  PRIMARY_DARK: "#61affe",
  SECONDARY: "#dff5e9",
  SECONDARY_DARK: "#49cc90",
  TERTIARY: "#feecd9",
  TERTIARY_DARK: "#fca130",
  QUATERNARY: "#e0f9f3",
  QUATERNARY_DARK: "#50e3c2",
  QUINARY: "#ffdbd9",
  QUINARY_DARK: "#f93e3e",
  TEXT: "#3b4151",
};

export enum METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}
export const METHOD_COLORS: COLORS_TYPE = {
  [METHODS.GET]: {
    border: COLORS.PRIMARY_DARK,
    background: COLORS.PRIMARY,
    text: COLORS.TEXT,
  },
  [METHODS.POST]: {
    border: COLORS.SECONDARY_DARK,
    background: COLORS.SECONDARY,
    text: COLORS.TEXT,
  },
  [METHODS.PUT]: {
    border: COLORS.TERTIARY_DARK,
    background: COLORS.TERTIARY,
    text: COLORS.TEXT,
  },
  [METHODS.PATCH]: {
    border: COLORS.QUATERNARY_DARK,
    background: COLORS.QUATERNARY,
    text: COLORS.TEXT,
  },
  [METHODS.DELETE]: {
    border: COLORS.QUINARY_DARK,
    background: COLORS.QUINARY,
    text: COLORS.TEXT,
  },
};
