import styles from "./EmptyState.module.scss";
import { ReactNode } from "react";
import { Empty } from "antd";
type EmptyStatePropsType = {
  text?: ReactNode;
  title?: string;
};
const EmptyState = ({ text, title }: EmptyStatePropsType) => {
  return (
    <Empty
      description={
        <div>
          <p className={styles.emptyTitle}>{title}</p>
          {text}
        </div>
      }
      className={styles.emptyStateWrapper}
      data-testid="empty-state"
    />
  );
};

EmptyState.defaultProps = {
  text: "Stay tuned at apigale.com",
  title: "Coming soon...",
};

export default EmptyState;
