import { SettingOutlined } from "@ant-design/icons";

import { PermissionEnum } from "@app/features/permissions/constants/permissions.scopes";
import ServiceSpecifications from "@app/features/services-specifications/screens/ServiceSpecifications/ServiceSpecifications";
import CreateServiceSpecification from "@app/features/services-specifications/screens/CreateServiceSpecification";
import { RouteItemDef } from "@app/types/route.types";
import EditServiceSpecification from "@app/features/services-specifications/screens/EditServiceSpecification";
import OperationsServiceSpecification from "@app/features/services-specifications/screens/OperationsServiceSpecification";

export const SERVICES_SPECIFICATIONS: RouteItemDef = {
  id: "service-specifications",
  path: "/service-specifications",
  title: "serviceSpecificationsNavigation.title",
  icon: SettingOutlined,
  component: ServiceSpecifications,
  // permissions: [PermissionEnum.SERVICE_SPECIFICATIONS_LIST],
};

export const SERVICES_SPECIFICATIONS_CREATE: RouteItemDef = {
  id: "service-specifications-create",
  path: "/service-specifications/create",
  title: "serviceSpecificationsCreate.title",
  hideInNavigation: true,
  component: CreateServiceSpecification,
  permissions: [PermissionEnum.SERVICE_SPECIFICATIONS_CREATE],
};

export const SERVICES_SPECIFICATIONS_EDIT: RouteItemDef = {
  id: "service-specifications-create",
  path: "/service-specifications/:id/edit",
  link: (id: string) => `/service-specifications/${id}/edit`,
  title: "serviceSpecificationsEdit.title",
  hideInNavigation: true,
  component: EditServiceSpecification,
  permissions: [PermissionEnum.SERVICE_SPECIFICATIONS_EDIT],
};

export const SERVICES_SPECIFICATIONS_OPERATIONS: RouteItemDef = {
  id: "service-specifications-api-operations",
  path: "/service-specifications/:id/api-operations",
  link: (id: string) => `/service-specifications/${id}/api-operations`,
  title: "serviceSpecificationsAPIOperationsEdit.title",
  hideInNavigation: true,
  component: OperationsServiceSpecification,
  permissions: [PermissionEnum.SERVICE_SPECIFICATIONS_EDIT],
};

export const SERVICES_SPECIFICATIONS_ROUTES = [
  SERVICES_SPECIFICATIONS_OPERATIONS,
  SERVICES_SPECIFICATIONS_CREATE,
  SERVICES_SPECIFICATIONS_EDIT,
  SERVICES_SPECIFICATIONS,
];
