import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  PrivateServiceSpecificationDef,
  PublicServiceSpecificationsApiServicesGetRequest,
} from "@app/@generated";
import { serviceSpecificationsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";
import { ErrorDef } from "@app/types/api.types";

export const SERVICE_SPECIFICASTIONS_KEY = "serviceSpecifications";

interface SliceState {
  serviceSpecifications: PrivateServiceSpecificationDef[] | [];
  isLoading: boolean;
  error: ErrorDef | null;
}

const initialState: SliceState = {
  serviceSpecifications: [],
  isLoading: false,
  error: null,
};

export const getServiceSpecifications = createAsyncThunk(
  "sSpecifications/getServiceSpecifications",
  async (
    params: PublicServiceSpecificationsApiServicesGetRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await serviceSpecificationsApi.serviceSpecificationsGet({
        ...params,
      });
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const serviceSpecificationsSlice = createSlice({
  name: SERVICE_SPECIFICASTIONS_KEY,
  initialState,
  reducers: {
    clearServices: state => {
      state.serviceSpecifications = [];
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getServiceSpecifications.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.serviceSpecifications = [];
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(
      getServiceSpecifications.fulfilled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state, action: any) => {
        state.isLoading = false;
        state.serviceSpecifications =
          (action.payload.data as PrivateServiceSpecificationDef[]) ?? [];
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(getServiceSpecifications.rejected, (state, action: any) => {
      state.isLoading = false;
      state.error = action.payload as ErrorDef;
    });
  },
});

//export const {} = serviceSpecificationsSlice.actions;

export const serviceSpecificationsReducer = serviceSpecificationsSlice.reducer;
