const InfoPreview = () => (
  <div>
    <p>Discover Apigale, your go-to API Service Blueprint Platform! </p>
      <p>Unlock possibilities:</p>
    <ul>
        <li>
            API Providers to effortlessly manage and share your API Endpoints.
        </li>
        <li>
            API Consumers to seamlessly explore, access API Documentation, and try APIs.
        </li>
    </ul>
      <p>Anticipate these cutting-edge features on our horizon:</p>
      <ul>
          <li>Unleash the power of End-to-End API Management.</li>
          <li>Elevate your developer experience with the API Drill-down: from API Services through Source Code to
              Deployment Instances.
          </li>
      </ul>
      <p>
          Embark on the Beta Release journey with limited yet impactful features.
      </p>
      <p>
          Your feedback is invaluable as we shape the future of APIs. Dive in at{" "}
          <a href="https://apigale.com">apigale.com</a>.
      </p>
  </div>
);

export default InfoPreview;
