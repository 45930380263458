import { message } from "antd";
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";

import { ENV } from "@app/constants/env";
import { clearTokens, getTokens } from "@app/helpers/auth.helpers";

declare module "axios" {
  export interface AxiosRequestConfig {
    hideDefaultError?: boolean;
  }
}

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: InternalAxiosRequestConfig) => {
  const accessToken = await getTokens();

  if (accessToken) {
    request.headers.set("SESSION", accessToken);
  }

  return request;
};

/**
 * Axios error interceptor
 * @param {AxiosError} axiosError
 */
const errorInterceptor = (axiosError: AxiosError) => {
  if (axiosError?.response) {
    // Handle error here
    const { config, response } = axiosError;

    if (response.status === 401) {
      // unauthorized error
      clearTokens();
      window.location.href = "/";
    }
    // @ts-ignore
    if (response?.data?.message && !config.hideDefaultError) {
      // @ts-ignore
      message.error(response.data.message);
    }
  }
  return Promise.reject(axiosError);
};

/** Setup an API instance */
export const axiosInstance = axios.create({
  baseURL: ENV.API_HOST,
  headers: {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
    "Access-Control-Allow-Origin": "*",
    withCredentials: true,
  },
});

axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.response.use(res => res, errorInterceptor);
