import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import cx from "classnames";
import styles from "./OperationsSidebar.module.scss";
import { PlusSquareFilled } from "@ant-design/icons";
import {
  Operation,
  OperationsItemType,
  OperationsType,
  PrivateOperationsItemType,
  PublicOperationsItemType,
} from "@app/types/api.types";

type OperationsSidebarProps = {
  operations: null | OperationsType<
    PrivateOperationsItemType | PublicOperationsItemType | Operation
  >;
  usedOperations: Array<string>;
  isLoading: boolean;
  onAdd: (operation: OperationsItemType) => void;
};
const OperationsSidebar = ({
  operations,
  isLoading,
  onAdd,
  usedOperations,
}: OperationsSidebarProps) => {
  const handleClick = (operation: OperationsItemType) => {
    onAdd(operation);
  };
  return (
    <div id="wrap-sidebar-operations" className={styles.list}>
      {isLoading && <LoadingSpinner />}
      {!isLoading &&
        operations &&
        Object.keys(operations).map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) =>
            !usedOperations.includes(operations[item].id) &&
            operations[item] && (
              <div
                key={operations[item].id}
                className={cx(
                  styles.item,
                  styles[operations[item].httpMethod.toLowerCase() || ""]
                )}
              >
                <strong>{operations[item].httpMethod}</strong>{" "}
                {operations[item].path}
                <span
                  className={styles.buttonAdd}
                  onClick={() => handleClick(operations[item])}
                >
                  <PlusSquareFilled />
                </span>
              </div>
            )
        )}
    </div>
  );
};

export default OperationsSidebar;
