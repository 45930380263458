export const SERVICES_TRANSLATIONS_EN = {
  "servicesNavigation.title": "Catalog",
  "servicesScreen.title": "API Services Catalog",
  "services.title": "Services",
  "servicesApi.title": "API Specification",
  "servicesEvents.title": "API Events",
  "servicesSchema.title": "API Journey",
  "servicesTools.title": "API Tools",
  "servicesOperationsTools.title": "API Operations",
  "servicesEnvironments.title": "API Environments",
  "favoritesScreen.title": "Favorites",
};
