import { useState } from "react";

import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import cx from "classnames";
import AceEditor from "react-ace";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SwaggerUI from "swagger-ui-react";
import yaml from "yaml";
import "brace/mode/yaml";

import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import "swagger-ui-react/swagger-ui.css";
import "./ApiPreview.styles.scss";

import { API_EDITOR_MODE } from "@app/constants/common";

type ApiPreviewPropsType = {
  isEditable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiSpecification?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  defaultMode?: string;
  url?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertToYaml = (apiSpecification: any): string => {
  if (typeof apiSpecification === "string") return apiSpecification;
  else return yaml.stringify(apiSpecification);
};

const ApiPreview = ({
  isEditable,
  onChange,
  defaultMode,
  url,
  apiSpecification,
}: ApiPreviewPropsType) => {
  const [apiSpec, setApiSpec] = useState<string>(
    apiSpecification ? convertToYaml(apiSpecification) : ""
  );
  const [showEditor, setShowEditorStatus] = useState(
    defaultMode === API_EDITOR_MODE.ALL ||
      defaultMode === API_EDITOR_MODE.EDITOR
  );
  const [showSwagger, setShowSwaggerStatus] = useState(
    defaultMode === API_EDITOR_MODE.ALL ||
      defaultMode === API_EDITOR_MODE.PREVIEW
  );

  // const [controlPosition, setControlPosition] = useState("translateY(0px)");
  const [swaggerLoaded, setSwaggerStatus] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (newValue: any) => {
    setApiSpec(newValue);
    if (onChange) onChange(newValue ? newValue : {});
  };

  const handleEditorClick = () => {
    if (!showSwagger && showEditor) {
      setShowSwaggerStatus(true);
    }
    setShowEditorStatus(!showEditor);
  };
  const handleSwaggerClick = () => {
    if (showSwagger && !showEditor) {
      setShowEditorStatus(true);
      setSwaggerStatus(true);
    }
    setShowSwaggerStatus(!showSwagger);
  };

  return (
    <div className="preview-api">
      {isEditable && (
        <div className="wrap-buttons">
          <Button
            type="text"
            icon={<EditOutlined className="icon" />}
            className={cx("button", { active: showEditor })}
            onClick={handleEditorClick}
          />

          <Button
            type="text"
            icon={<FileTextOutlined className="icon" />}
            className={cx("button", { active: showSwagger })}
            onClick={handleSwaggerClick}
          />
        </div>
      )}
      <Row justify="space-between" align="stretch">
        {showEditor && isEditable && (
          <Col xs={24} xl={showSwagger ? 12 : 24}>
            {(swaggerLoaded || !showSwagger) && (
              <AceEditor
                mode="yaml"
                value={apiSpec || ""}
                onChange={handleChange}
                name="swagger-preview"
                editorProps={{ $blockScrolling: true }}
                height="100%"
                width="100%"
                style={{ minHeight: "60vh" }}
                readOnly={!isEditable}
              />
            )}
          </Col>
        )}

        {showSwagger && (
          <Col
            xs={24}
            xl={showEditor && isEditable ? 12 : 24}
            className="preview-api-swagger"
          >
            <SwaggerUI
              onComplete={() => {
                setSwaggerStatus(true);
              }}
              {...{
                ...(apiSpec && { spec: { ...yaml.parse(apiSpec) } }),
                ...(url && !apiSpecification && { url: url }),
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

ApiPreview.defaultProps = {
  defaultMode: API_EDITOR_MODE.ALL,
};

export default ApiPreview;
