import {
  Permission,
  PermissionEnum,
} from "@app/features/permissions/permissions";
import Button from "@app/components/Common/atoms/Button/Button";

import { useTranslation } from "react-i18next";

import styles from "@app/features/services-specifications/screens/ServiceSpecifications/ServiceSpecifications.module.scss";

import { ItemModalEnum } from "@app/constants/route.constants";
import * as modalAction from "@app/helpers/modal.helper";
import useShowModal from "@app/hooks/useShowModal";
import { SERVICES_SPECIFICATIONS_EDIT } from "@app/features/services-specifications";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceSpecificationControls = (props: any) => {
  const { t } = useTranslation();
  const { openModal } = useShowModal();

  const handleDelete = () => {
    const { id, attributes } = props.data;

    openModal(
      modalAction.custom({
        customAction: ItemModalEnum.DELETE,
        id: id,
        name: attributes?.name,
      })
    );
  };

  return (
    <>
      {props.data?.id && SERVICES_SPECIFICATIONS_EDIT.link && (
        <Permission
          requiredPermissions={[PermissionEnum.SERVICE_SPECIFICATIONS_EDIT]}
          key="edit"
        >
          <Button
            type="primary"
            className={styles.button}
            target="_blank"
            to={SERVICES_SPECIFICATIONS_EDIT.link(props.data.id)}
          >
            {t("serviceSpecifications.edit")}
          </Button>
        </Permission>
      )}
      <Permission
        requiredPermissions={[PermissionEnum.SERVICE_SPECIFICATIONS_DELETE]}
        key="delete"
      >
        <Button
          type="primary"
          danger
          className={styles.button}
          onClick={handleDelete}
        >
          {t("serviceSpecifications.delete")}
        </Button>
      </Permission>
    </>
  );
};

export default ServiceSpecificationControls;
