import {
  LinkOperationType,
  Operation,
  OperationsType,
} from "@app/types/api.types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeUsedLinks = (data: any): Array<string> => {
  const used: Array<string> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((item: any) => {
    if (
      typeof item.attributes.xCoordinate !== "undefined" &&
      typeof item.attributes.yCoordinate !== "undefined" &&
      item.attributes.xCoordinate !== null &&
      item.attributes.yCoordinate !== null
    ) {
      used.push(item.id);
    }
  });
  return used;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeOperations = (data: any) => {
  const operations: OperationsType<Operation> = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((item: any) => {
    operations[item.id] = {
      id: item.id,
      ...item.attributes,
    };
  });

  return operations;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeOperationsLinks = (data: any) => {
  const links: Array<LinkOperationType> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((link: any) => {
    const inwardApiOperation = link.relationships.inwardApiOperation.data.id;
    const outwardApiOperation = link.relationships.outwardApiOperation.data.id;
    links.push({
      ...link.attributes,
      inwardApiOperation: inwardApiOperation,
      outwardApiOperation: outwardApiOperation,
    });
  });

  return links;
};
