import { Configuration } from "@app/@generated";
import { getCookieTokens } from "@app/helpers/auth.helpers";

import { ENV } from "./env";

/**
 * API response status codes enum
 */
export enum ApiStatusCodes {
  SUCCESS = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  CONFLICT = 409,
}

const BASE_PATH = ENV.API_HOST;
const BASE_OPTIONS = {
  headers: {
    "A-Meta": `web;${ENV.NODE_ENV}`,
  },
};

const apiClientConfiguration = new Configuration();
apiClientConfiguration.basePath = BASE_PATH;
apiClientConfiguration.baseOptions = BASE_OPTIONS;

/** Used for generating access token from refresh token */
const apiAuthClientConfiguration = new Configuration();
apiAuthClientConfiguration.basePath = BASE_PATH;
apiAuthClientConfiguration.baseOptions = BASE_OPTIONS;
apiAuthClientConfiguration.accessToken = () => {
  return getCookieTokens().refreshToken ?? "";
};

export { apiClientConfiguration, apiAuthClientConfiguration };
