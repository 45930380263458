import {
  PrivateServiceSpecificationsApi,
  PrivateServiceSpecificationsAPIOperationsApi,
  PrivateServiceSpecificationsAPIToolsApi,
} from "@app/@generated";
import { axiosInstance } from "@app/api/api";
import { apiClientConfiguration } from "@app/constants/api.constants";

const serviceSpecificationsApi = new PrivateServiceSpecificationsApi(
  apiClientConfiguration,
  undefined,
  axiosInstance
);

const serviceSpecificationsAPIOperationsApi =
  new PrivateServiceSpecificationsAPIOperationsApi(
    apiClientConfiguration,
    undefined,
    axiosInstance
  );

const serviceSpecificationsAPIToolsApi =
  new PrivateServiceSpecificationsAPIToolsApi(
    apiClientConfiguration,
    undefined,
    axiosInstance
  );

export {
  serviceSpecificationsApi,
  serviceSpecificationsAPIOperationsApi,
  serviceSpecificationsAPIToolsApi,
};
