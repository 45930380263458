import React from "react";
import { Helmet } from "react-helmet-async";
import { PROJECT_TITLE } from "@app/constants/common";

type HelmetWrapperProps = {
  children?: React.ReactNode | string;
  title?: string;
};

const HelmetWrapper: React.FC<HelmetWrapperProps> = ({
  title = "",
  children,
}) => (
  <Helmet>
    <title>
      {title ? `${PROJECT_TITLE} | ` : PROJECT_TITLE}
      {title}
    </title>
    {children}
  </Helmet>
);

export default HelmetWrapper;
