import { useEffect, useState } from "react";
import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";

import { logger } from "@app/utils/logger";
import Button from "@app/components/Common/atoms/Button/Button";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Input, Select } from "antd";
import Form, { FormItem } from "@app/components/Common/atoms/Form";

import styles from "./ServiceSpecificationTools.module.scss";
import { TOOLS_CATEGORY_OPTIONS } from "@app/constants/common";
import TextArea from "antd/lib/input/TextArea";
import { ITool, Tools } from "@app/types/common.types";
import axios, { AxiosResponse } from "axios";
import { serviceSpecificationsAPIToolsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";
import {
  PrivateServiceSpecificationApiToolDefTypeEnum,
  PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPostRequest,
} from "@app/@generated";
import { DeserializeData } from "@app/utils/common";
import { useForm } from "antd/lib/form/Form";

import { ApiTool } from "@app/components/ApiTool";
import { modalConfirm } from "@app/components/Common/atoms/ModalConfirm/ModalConfirm";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceSpecificationTools = (props: any) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tools, setTools] = useState<Tools | null>(null);

  const [showAddForm, setFormStatus] = useState<boolean>(false);
  const { id } = props.data;
  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const [isFetching, setFetchingStatus] = useState<boolean>(false);
  const [isDeleting, setDeletingStatus] = useState<string | null>(null);

  const [formInstance] = useForm();

  const getTools = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsAPIToolsApi.serviceSpecificationsServiceSpecificationIdApiToolsGet(
          {
            serviceSpecificationId: id,
          }
        );

      DeserializeData(data).then(res => {
        const tools: Tools = {};

        res.map((tool: ITool) => {
          tools[tool.category] = tools[tool.category]
            ? [...tools[tool.category], tool]
            : [tool];
        });
        setTools(tools);
      });
      // const links = deserializeOperationsLinks(data.data);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    getTools();
  }, [id]);

  const saveTools = async (values: ITool) => {
    try {
      const params: PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPostRequest =
        {
          serviceSpecificationId: id,
          inlineObject1Def: {
            data: {
              type: PrivateServiceSpecificationApiToolDefTypeEnum.SERVICE_SPECIFICATION_API_TOOLS,
              attributes: {
                ...values,
              },
            },
          },
        };

      await serviceSpecificationsAPIToolsApi.serviceSpecificationsServiceSpecificationIdApiToolsPost(
        {
          ...params,
        }
      );
    } catch (error: unknown) {
      logger.error(error);
    }
  };

  const handleFormControl = () => {
    setFormStatus(!showAddForm);
  };

  const handleSubmit = async (values: ITool) => {
    await saveTools(values);
    setFormStatus(false);
    await getTools();
  };

  const deleteTool = async (apiToolId: string) => {
    setDeletingStatus(apiToolId);
    try {
      await serviceSpecificationsAPIToolsApi.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete(
        {
          serviceSpecificationId: id,
          apiToolId: apiToolId,
        }
      );
      await getTools();
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setDeletingStatus(null);
    }
  };

  const handleDelete = async (apiToolId: string) => {
    modalConfirm(t, {
      title: t("default.deleteTitle"),
      content: t("default.tools.confirmText"),
      okText: t("default.delete"),
      onOk: () => {
        deleteTool(apiToolId);
      },
    });
  };

  // eslint-disable-next-line
  const handleInputURL = (e: Event) => {
    const target = e.target as HTMLInputElement;
    setFetchingStatus(true);
    axios
      .get(
        `https://opengraph.io/api/1.1/site/${encodeURIComponent(
          target.value
        )}?accept_lang=auto&auto_proxy=true&app_id=ebf9dc03-26c5-4050-a1bd-2cf4f04b46dd`
      )
      .then(res => {
        formInstance.setFieldsValue({
          title: res.data.openGraph.title,
          description: res.data.openGraph.description,
        });
      })
      .catch((error: unknown) => {
        logger.error(error);
      })
      .finally(() => setFetchingStatus(false));
  };

  const initialValues = {};

  return (
    <div>
      <div id="wrap-api-tools" style={{ position: "relative" }}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div>
            {showAddForm && (
              <div className={styles.form}>
                {isFetching && (
                  <div className={styles.fetchingForm}>
                    <LoadingSpinner />
                  </div>
                )}
                <Form
                  values={initialValues}
                  onFinish={handleSubmit}
                  className={styles.form}
                  formInstance={formInstance}
                >
                  <FormItem
                    name="url"
                    label={t("API Tool URL")}
                    rules={[
                      {
                        required: true,
                        pattern:
                          // eslint-disable-next-line
                          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                        message: t("Invalid URL format"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("Input URL")}
                      // eslint-disable-next-line
                      // @ts-ignore
                      onBlur={handleInputURL}
                    />
                  </FormItem>
                  <FormItem name="title" label={t("Tile")}>
                    <Input placeholder={t("Input title")} />
                  </FormItem>
                  <FormItem name="description" label={t("Description")}>
                    <TextArea rows={4} placeholder={t("default.description")} />
                  </FormItem>

                  <div className={styles.formControl}>
                    <FormItem
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: t("This field is required"),
                        },
                      ]}
                    >
                      <Select
                        className={styles.category}
                        placeholder="Category"
                        options={TOOLS_CATEGORY_OPTIONS}
                      />
                    </FormItem>
                    <div>
                      <Button
                        onClick={handleFormControl}
                        htmlType="button"
                        className={styles.button}
                      >
                        {t("default.cancelTitle")}
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.button}
                      >
                        {t("default.addTitle")}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
            {!showAddForm && (
              <div className={styles.addTools}>
                <Button onClick={handleFormControl}>
                  <PlusOutlined />{" "}
                  <span>{t("serviceSpecificationsAPIToolsAdd.title")}</span>
                </Button>
              </div>
            )}

            {tools &&
              Object.keys(tools).map(group => (
                <div className={styles.tools}>
                  <div className={styles.toolGroup}>
                    <h6 className={styles.toolGroupTitle}>
                      {t(`default.tools.${group}`)}
                    </h6>

                    {tools[group]?.map((tool: ITool) => (
                      <div className={styles.tool}>
                        <ApiTool {...tool} />
                        <div>
                          {/*<Button icon type="primary"  className={styles.toolControls}>*/}
                          {/*  <EditOutlined />*/}
                          {/*</Button>*/}
                          <Button
                            icon
                            type="primary"
                            className={styles.toolControls}
                            onClick={() => handleDelete(tool.id)}
                            loading={isDeleting === tool.id}
                          >
                            <DeleteOutlined />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceSpecificationTools;
