import { useEffect, useState } from "react";

import { ItemModalEnum } from "@app/constants/route.constants";

import { useAppDispatch, useAppSelector } from "@app/store/store";

import { hideModal, openModal as openModals } from "@app/store/ui";
import { SearchParamDef } from "@app/hooks/useSearchParams";

interface ShowModalConfig {
  customEntryType?: string;
  customActionType?: string;
}

function useShowModal(config?: ShowModalConfig) {
  const { customEntryType, customActionType } = config ?? {};
  const [isShowModal, setShowModal] = useState(false);
  const { modal } = useAppSelector(state => state.ui);
  const dispatch = useAppDispatch();
  const action = modal?.action;
  const entryId = modal?.entryId;
  const entryType = modal?.entryType;
  const customAction = modal?.customAction;

  useEffect(() => {
    setShowModal(
      (action === ItemModalEnum.EDIT ||
        action === ItemModalEnum.ADD ||
        !!customActionType) &&
        entryType === customEntryType &&
        customAction === customActionType
    );
  }, [action, customAction, customActionType, customEntryType, entryType]);

  const closeModal = () => {
    dispatch(hideModal());
  };

  const openModal = (values: SearchParamDef) => {
    dispatch(openModals(values));
  };

  return { isShowModal, closeModal, openModal, action, entryId, entryType };
}

export default useShowModal;
