import {
  Permission,
  PermissionEnum,
} from "@app/features/permissions/permissions";
import Button from "@app/components/Common/atoms/Button/Button";
import styles from "@app/features/services-specifications/screens/ServiceSpecifications/ServiceSpecifications.module.scss";

import { useTranslation } from "react-i18next";

import { SERVICES_SPECIFICATIONS_OPERATIONS } from "@app/features/services-specifications";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceSpecificationOperationControls = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      {props.data?.id && SERVICES_SPECIFICATIONS_OPERATIONS.link && (
        <Permission
          requiredPermissions={[PermissionEnum.SERVICE_SPECIFICATIONS_EDIT]}
          key="edit"
        >
          <Button
            type="primary"
            className={styles.button}
            target="_blank"
            to={SERVICES_SPECIFICATIONS_OPERATIONS.link(props.data.id)}
          >
            {t("serviceSpecifications.edit")}
          </Button>
        </Permission>
      )}
    </>
  );
};

export default ServiceSpecificationOperationControls;
