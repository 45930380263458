import EventsCommingSoon from "@app/assets/images/commingsoon/services-events.png";
import CommingSoon from "@app/components/CommingSoon";

const ServiceEvents = () => {
  //props: any
  return (
    <CommingSoon
      preview={<img src={EventsCommingSoon} alt="Events" />}
      description={
        <>
          <p>
            This feature is currently in testing and will be available in the
            Public Release soon.
          </p>
          <p>
            As an API Consumer, you may need to have the log of all the API
            calls, either for debugging or security audit.
          </p>
        </>
      }
    />
  );
};

export default ServiceEvents;
