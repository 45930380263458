import { Layout } from "antd";

import { ReactComponent as Commingsoon } from "@app/assets/images/comingsoon.svg";

import { ReactNode } from "react";
import cx from "classnames";

import styles from "./CommingSoon.module.scss";

type CommingSoonPropsType = {
  preview?: ReactNode;
  title?: string;
  description?: string | ReactNode;
};
const CommingSoon = ({ title, preview, description }: CommingSoonPropsType) => {
  return (
    <Layout className={styles.layout} data-testid="comming-soon">
      <div
        className={cx(styles.img, {
          [styles.customImg]: preview,
        })}
      >
        {preview ? preview : <Commingsoon />}
      </div>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.text}>{description}</div>
    </Layout>
  );
};

CommingSoon.defaultProps = {
  title: "Coming Soon!",
  description: "We are working hard on this page and will be back soon.",
};

export default CommingSoon;
