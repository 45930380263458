import { memo, ReactNode } from "react";

import { Col, Layout, PageHeader, PageHeaderProps, Row } from "antd";
import cx from "classnames";

import Breadcrumbs, {
  BreadcrumbsRouteType,
} from "@app/components/Common/molecules/Breadcrumbs/Breadcrumbs";
import HelmetWrapper from "@app/components/HelmetWrapper";

import styles from "./ContentLayout.module.scss";

const { Content } = Layout;

type ContentLayoutProps = {
  header?: PageHeaderProps;
  filters?: ReactNode;
  children: ReactNode;
  sidebar?: ReactNode | null;
  noContentStyle?: boolean;
  additionalSorter?: ReactNode;
  innerTitle?: string;
  subMenu?: boolean;
  breadcrumbs?: BreadcrumbsRouteType[];
  title?: string;
  contentCentered?: boolean;
};

const ContentLayout = ({
  header,
  filters,
  children,
  noContentStyle,
  additionalSorter,
  innerTitle,
  sidebar,
  breadcrumbs,
  title,
  contentCentered,
}: ContentLayoutProps) => {
  return (
    <Layout className={styles.container}>
      <HelmetWrapper title={title} />
      {header && (
        <PageHeader
          ghost={false}
          {...header}
          className={cx(styles.pageHeader, header.className)}
        />
      )}
      {breadcrumbs && (
        <div>
          <div
            style={{
              padding: "5px 24px",
              margin: "0 0 24px",
              background: "#fff",
            }}
          >
            <Breadcrumbs items={breadcrumbs} />
          </div>
        </div>
      )}
      <Row
        justify="space-between"
        align="stretch"
        className={cx({
          [styles.rowFullScreen]: contentCentered,
        })}
      >
        {sidebar && (
          <Col xs={24} xl={6}>
            {sidebar}
          </Col>
        )}
        <Col xs={24} xl={sidebar ? 18 : 24}>
          {filters && (
            <div className={cx(styles.filters, styles.content)}>{filters}</div>
          )}
          <div
            className={cx(styles.appsContainer, {
              [styles.rowFullScreen]: contentCentered,
            })}
          >
            <Content
              className={cx("apps-main-content", {
                [styles.content]: !noContentStyle,
                [styles.contentCentered]: contentCentered,
              })}
            >
              {additionalSorter && (
                <Row justify="space-between" align="middle">
                  <Col xs={24} xl={17}>
                    <div className={styles.innerHeader}>{innerTitle}</div>
                  </Col>
                  <Col xs={24} xl={7}>
                    {additionalSorter}
                  </Col>
                </Row>
              )}

              {children}
            </Content>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default memo(ContentLayout);
