import { useTranslation } from "react-i18next";
import { useState } from "react";
import yaml from "yaml";
import { ServiceSpecificationSecurityLevelEnum } from "@app/@generated";

import { Button, Input, Row, Col, Checkbox } from "antd";

import Form, { FormItem } from "@app/components/Common/atoms/Form";
import ApiPreview from "@app/components/ApiPreview";

import { API_EDITOR_MODE } from "@app/constants/common";

import styles from "./CreateSpecificationForm.module.scss";
import { useForm } from "@app/components/Common/atoms/Form/Form";

type CreateSpecificationFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (params: any) => void;
};
const CreateSpecificationForm = ({
  initialValues,
  onSubmit,
}: CreateSpecificationFormProps) => {
  const [apiSpec, setApiSpec] = useState(
    initialValues && initialValues.apiSpecification
      ? yaml.stringify(initialValues.apiSpecification)
      : ""
  );

  const { t } = useTranslation();
  const [formInstance] = useForm();

  const { TextArea } = Input;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (values: any) => {
    const { is_public_level, ...otherValues } = values;

    const params = {
      ...otherValues,
      apiSpecification: yaml.parse(apiSpec) || {},
      securityLevel: is_public_level
        ? ServiceSpecificationSecurityLevelEnum.PUBLIC.toUpperCase()
        : ServiceSpecificationSecurityLevelEnum.PRIVATE.toUpperCase(),
    };

    onSubmit(params);
  };

  const handleChangeApiSpec = (spec: string) => {
    setApiSpec(spec);
  };

  return (
    <Form
      values={initialValues}
      onFinish={handleSubmit}
      className={styles.form}
      formInstance={formInstance}
    >
      <Row gutter={20}>
        <Col span={12} className="gutter-row">
          <FormItem
            name="title"
            label={t("Title")}
            rules={[
              {
                required: true,
                message: t("This field is required"),
              },
            ]}
          >
            <Input placeholder={t("My awesome API service")} />
          </FormItem>
        </Col>
        <Col span={12} className="gutter-row">
          <FormItem
            name="name"
            label={t("Slug")}
            rules={[
              {
                required: true,
                message: t("This field is required"),
              },
            ]}
          >
            <Input placeholder={t("my-awesome-api-service")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={24} className="gutter-row">
          <FormItem
            name="description"
            label={t("default.description")}
            rules={[
              {
                required: true,
                message: t("This field is required"),
              },
            ]}
          >
            <TextArea rows={6} placeholder={t("default.description")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={24} className="gutter-row">
          <FormItem
            name="is_public_level"
            valuePropName="checked"
            label={t("default.privacy")}
          >
            <Checkbox>{t("default.publicTitle")}</Checkbox>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={24} className="gutter-row">
          <FormItem name="apiSpecification" label={t("default.apiSpec")}>
            <ApiPreview
              isEditable
              apiSpecification={apiSpec}
              defaultMode={API_EDITOR_MODE.EDITOR}
              onChange={handleChangeApiSpec}
            />
          </FormItem>
        </Col>
      </Row>

      <div className={styles.wrapButtons}>
        <Button
          href="/service-specifications"
          htmlType="button"
          className={styles.button}
        >
          {t("default.cancelTitle")}
        </Button>
        <Button type="primary" htmlType="submit" className={styles.button}>
          {t("default.saveTitle")}
        </Button>
      </div>
    </Form>
  );
};

export default CreateSpecificationForm;
