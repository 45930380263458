import { Menu } from "antd";
import {
  MenuItemType,
  SidebarType,
} from "@app/components/Common/atoms/Sidebar/Sidebar.types";

import "./Sidebar.scss";

const Sidebar = ({ items, onSelectMenu }: SidebarType) => {
  const handleSelect = (item: MenuItemType) => {
    if (onSelectMenu) onSelectMenu(item);
  };

  return (
    <div className="apps-sidebar">
      <div className="apps-sidebar-content">
        <Menu mode="inline" items={items} onClick={handleSelect} />
      </div>
    </div>
  );
};

export default Sidebar;
