import LoginAuthorized from "@app/features/auth/screens/LoginAuthorized/LoginAuthorized";
import { RouteItemDef } from "@app/types/route.types";

import { AuthRoutesEnum } from "../constants/auth.paths";

const LOGIN_AUTHORIZED: RouteItemDef = {
  id: "login-google-authorized",
  exact: true,
  path: AuthRoutesEnum.LOGIN_AUTHORIZED,
  component: LoginAuthorized,
};

export const AUTH_ROUTES = [LOGIN_AUTHORIZED];
