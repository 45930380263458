import moment from "moment";
import cookie from "react-cookies";

import { TokensDef } from "@app/@generated";
import { AUTH_ACCESS_TOKEN } from "../features/auth/constants/auth.keys";

/**
 * Checks token from session cookie or get refreshed token
 */
export const getCookieTokens = () => {
  const value = cookie.load(AUTH_ACCESS_TOKEN);

  const expiry = new Date(value?.expiresAt);

  return {
    accessToken: value?.accessToken,
    refreshToken: value?.refreshToken,
    isTokenExpired: moment().isAfter(expiry),
  };
};

export const saveTokens = (tokens?: TokensDef) => {
  if (tokens) {
    const cookieToken = {
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
      expiresAt: moment().add(tokens.expiresIn, "s"),
    };
    cookie.save(AUTH_ACCESS_TOKEN, cookieToken, { path: "/" });
  }
};

export const clearTokens = () => {
  return cookie.remove(AUTH_ACCESS_TOKEN, { path: "/" });
};

export const getTokens = async () => {
  const tokens = getCookieTokens();

  if (!tokens || !tokens.accessToken) {
    clearTokens();
    return null;
  }

  if (tokens.isTokenExpired) {
    // try {
    //   const newUser = await refreshAccessToken();
    //   saveTokens(newUser.data?.data);
    //   return newUser.data?.data?.accessToken;
    // } catch (e) {
    //   store.dispatch(setUnAuthenticated());
    //   return null;
    // }
  }

  return tokens.accessToken;
};
