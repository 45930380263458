import ServiceSpecificationOperationControls from "@app/features/services-specifications/components/ServiceSpecificationOperationControls";
import { Col, Row } from "antd";
import styles from "@app/components/SpecificationPreview/SpecificationPreview.module.scss";
import { DiagramWrapper } from "@app/components/DiagramWrapper/DiagramWrapper";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { serviceSpecificationsAPIOperationsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";
import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import {
  deserializeOperations,
  deserializeOperationsLinks,
  deserializeUsedLinks,
} from "@app/utils/operationLinks";
import { logger } from "@app/utils/logger";
import {
  LinkOperationType,
  Operation,
  OperationsType,
} from "@app/types/api.types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceSpecificationOperations = (props: any) => {
  const [operationsLinks, setOperationsLinks] =
    useState<null | Array<LinkOperationType>>(null);
  const [operations, setOperations] =
    useState<OperationsType<Operation> | null>(null);
  const [usedOperations, setUsedOperations] = useState<Array<string>>([]);

  const { id } = props.data;
  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const getOperationsLinks = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsAPIOperationsApi.serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(
          {
            serviceSpecificationId: id,
          }
        );

      const links = deserializeOperationsLinks(data.data);

      setOperationsLinks(links);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const getOperations = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsAPIOperationsApi.serviceSpecificationsServiceSpecificationIdApiOperationsGet(
          {
            serviceSpecificationId: id,
          }
        );
      const used = deserializeUsedLinks(data.data);
      const operations = deserializeOperations(data.data);
      setUsedOperations(used);
      setOperations(operations);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };
  useEffect(() => {
    getOperations();
    getOperationsLinks();
  }, [id]);
  return (
    <div>
      <div>
        <Row justify="space-between">
          <Col xs={15}></Col>
          <Col xs={9} className={styles.buttonGroup}>
            <ServiceSpecificationOperationControls data={props.data} />
          </Col>
        </Row>
      </div>
      <div id="wrap-api-operations" style={{ position: "relative" }}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <DiagramWrapper
            isEditable={false}
            nodes={operations}
            nodesUsed={usedOperations}
            operationsLinks={operationsLinks}
            container="wrap-api-operations"
          />
        )}
      </div>
    </div>
  );
};

export default ServiceSpecificationOperations;
