import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_EN } from "@app/i18n/localizations/en/translation";

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: TRANSLATIONS_EN,
      },
      // el: {
      // },
    },
    fallbackLng: "en",
    debug: true,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });
export default i18n;

// i18n.changeLanguage("el");
