import CommingSoon from "@app/components/CommingSoon";
import EnvsCommingSoon from "@app/assets/images/commingsoon/services-envs-1.png";

const ServiceEnvironments = () => {
  //props: any
  return (
    <CommingSoon
      preview={<img src={EnvsCommingSoon} alt="Environments" />}
      description={
        <>
          <p>
            This feature is currently in testing and will be available in the
            Public Release soon.
          </p>
          <p>
            As an API Consumer, you may need to have all the server information
            available, not needing to request it from every API Provider.
          </p>
        </>
      }
    />
  );
};

export default ServiceEnvironments;
