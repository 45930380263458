import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import styles from "./Breadcrumbs.module.scss";

export type BreadcrumbsRouteType = {
  path?: string;
  breadcrumbName: string;
};
interface BreadcrumbsProps {
  items: BreadcrumbsRouteType[];
}

function Breadcrumbs({ items }: BreadcrumbsProps) {
  return (
    <Breadcrumb>
      {items.map(item => (
        <Breadcrumb.Item key={item.breadcrumbName}>
          {item.path ? (
            <Link to={item.path} className={styles.breadcrumbItem}>
              {item.breadcrumbName}
            </Link>
          ) : (
            <span>{item.breadcrumbName}</span>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
