import CommingSoon from "@app/components/CommingSoon";
import EnvsCommingSoon from "@app/assets/images/commingsoon/services-envs.png";

const ServiceSpecificationEvents = () => {
  return (
    <CommingSoon
      preview={<img src={EnvsCommingSoon} alt="Environments" />}
      description={
        <>
          <p>
              This feature is currently in testing and will be available in the
              Public Release soon.
          </p>
            <p>
                As an API Provider, you may need to keep a registry of available
                environments and dynamically enable or disable them.
            </p>
        </>
      }
    />
  );
};

export default ServiceSpecificationEvents;
