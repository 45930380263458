import { memo } from "react";

import { Button as AntdButton } from "antd";
import { ButtonProps as AntdButtonProps } from "antd/es/button";

import { Link, LinkProps } from "react-router-dom";

import cx from "classnames";
import { isURL } from "@app/helpers/util.helper";

import "./Button.scss";

interface ButtonProps extends Omit<AntdButtonProps, "href"> {
  /**
   * Turn button into link, accepts internal and external links (optional)
   */
  to?: LinkProps["to"];
  /**
   * Remove horizontal padding (optional)
   */
  noPadding?: boolean;
}

const Button = memo(
  ({ to, className, children, noPadding, ...rest }: ButtonProps) => {
    const isExternalLink = typeof to === "string" && isURL(to);

    const buttonContent = (
      <AntdButton
        className={cx(className, {
          noPadding,
        })}
        {...(isExternalLink && {
          href: to as string,
          target: "_blank",
          rel: "noopener noreferrer",
        })}
        {...rest}
      >
        {children}
      </AntdButton>
    );

    // Only wrap in react router link, if internal link
    if (!isExternalLink && to) {
      return (
        <Link
          to={to}
          component={AntdButton}
          className={cx(className, {
            noPadding,
          })}
          {...rest}
        >
          {children}
        </Link>
      );
    }

    return buttonContent;
  }
);

export default Button;
