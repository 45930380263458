import { ServiceSpecificationApiOperationLinkPositionEnum } from "@app/@generated";

export const getLineXPosition = (
  width: number,
  height: number,
  value: number,
  position: ServiceSpecificationApiOperationLinkPositionEnum
): number => {
  switch (position) {
    case ServiceSpecificationApiOperationLinkPositionEnum.TOP:
      return width / 2 + value;
    case ServiceSpecificationApiOperationLinkPositionEnum.LEFT:
      return value;
    case ServiceSpecificationApiOperationLinkPositionEnum.RIGHT:
      return width + value;
    default:
      return width / 2 + value;
  }
};

export const getLineYPosition = (
  width: number,
  height: number,
  value: number,
  position: ServiceSpecificationApiOperationLinkPositionEnum
): number => {
  switch (position) {
    case ServiceSpecificationApiOperationLinkPositionEnum.TOP:
      return value;
    case ServiceSpecificationApiOperationLinkPositionEnum.LEFT:
      return height / 2 + value;
    case ServiceSpecificationApiOperationLinkPositionEnum.RIGHT:
      return height / 2 + value;
    default:
      return height + value;
  }
};
export const getLinePath = (
  width: number,
  height: number,
  path: Array<number>,
  move = 10,
  position1: ServiceSpecificationApiOperationLinkPositionEnum,
  position2: ServiceSpecificationApiOperationLinkPositionEnum
): Array<number> => {
  const x1 = getLineXPosition(width, height, path[0] || move, position1);
  const y1 = getLineYPosition(width, height, path[1] || move, position1);
  const x2 = getLineXPosition(width, height, path[2] || move, position2);
  const y2 = getLineYPosition(width, height, path[3] || move, position2);

  return [x1, y1, x2, y2];
};
