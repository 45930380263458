import SpecificationPreview from "@app/components/SpecificationPreview";
import ServiceControls from "@app/features/services/components/ServiceControls/ServiceControls";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceApi = (props: any) => {
  return (
    <SpecificationPreview
      isEditable={false}
      constrols={<ServiceControls {...props} />}
      {...props}
    />
  );
};

export default ServiceApi;
