import { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { AUTH_SESSION_PARAM } from "@app/features/auth/constants/auth.keys";
import { USER_ROLES_PERMISSIONS } from "@app/features/permissions/constants/permissions.scopes";
import { setPermissions } from "@app/features/permissions/permissions";
import { saveTokens } from "@app/helpers/auth.helpers";
import useQuery from "@app/hooks/useQuery";
import { useAppDispatch } from "@app/store/store";
import { setAuthenticated } from "@app/store/token.slice";

import styles from "./LoginAuthorized.module.scss";

const LoginAuthorized = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const history = useHistory();

  const accessToken = query.get(AUTH_SESSION_PARAM);

  useEffect(() => {
    if (accessToken) {
      dispatch(setAuthenticated());
      dispatch(setPermissions(USER_ROLES_PERMISSIONS.full));
      saveTokens({
        // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
        expiresIn: 1670796000000,
        accessToken,
      });
      history.push("/");
    }
  }, [dispatch, history, accessToken]);

  return <div className={styles.wrapper}>Loading...</div>;
};

export default LoginAuthorized;
