import { combineReducers } from "@reduxjs/toolkit";

import { authReducer, AUTH_FEATURE_KEY } from "@app/features/auth";

import {
  permissionsReducer,
  PERMISSIONS_FEATURE_KEY,
} from "@app/features/permissions/permissions";

import {
  servicesReducer,
  SERVICES_FEATURE_KEY,
} from "@app/features/services/services";

import {
  SERVICE_SPECIFICASTIONS_KEY,
  serviceSpecificationsReducer,
} from "@app/features/services-specifications/store/service-specifications.slice";
import { UI_SLICE_KEY, uiReducer } from "@app/store/ui";

import { tokenReducer, TOKEN_SLICE_KEY } from "./token.slice";

const rootReducer = combineReducers({
  // common
  [PERMISSIONS_FEATURE_KEY]: permissionsReducer,
  [TOKEN_SLICE_KEY]: tokenReducer,
  [UI_SLICE_KEY]: uiReducer,
  // features
  [AUTH_FEATURE_KEY]: authReducer,
  [SERVICES_FEATURE_KEY]: servicesReducer,
  [SERVICE_SPECIFICASTIONS_KEY]: serviceSpecificationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
