import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemModalEnum } from "@app/constants/route.constants";

export const UI_SLICE_KEY = "ui";

export type ModalsParamDef = {
  action?: ItemModalEnum;
  entryId?: string;
  entryName?: string;
  entryType?: string;
  customAction?: string;
};

interface UIState {
  isNavigationWide: boolean;
  modal: ModalsParamDef | null;
}

const initialState: UIState = {
  isNavigationWide: false,
  modal: null,
};

export const uiSlice = createSlice({
  name: UI_SLICE_KEY,
  initialState,
  reducers: {
    toggleNavigationWide: state => {
      state.isNavigationWide = !state.isNavigationWide;
    },
    openModal: (state, action: PayloadAction<ModalsParamDef>) => {
      state.modal = action.payload;
    },
    hideModal: state => {
      state.modal = null;
    },
  },
});

export const { toggleNavigationWide, openModal, hideModal } = uiSlice.actions;

export const uiReducer = uiSlice.reducer;
