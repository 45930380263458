import {
  Permission,
  PermissionEnum,
} from "@app/features/permissions/permissions";
import Button from "@app/components/Common/atoms/Button/Button";
import styles from "@app/features/services-specifications/screens/ServiceSpecifications/ServiceSpecifications.module.scss";

import { modalConfirm } from "@app/components/Common/atoms/ModalConfirm/ModalConfirm";
import {
  deleteServiceSubscribe,
  postServiceSubscribe,
} from "@app/features/services/store/services.slice";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@app/store/store";
import useNotification from "@app/hooks/useNotification";
import useShowModal from "@app/hooks/useShowModal";
import * as modalAction from "@app/helpers/modal.helper";
import { useState } from "react";
import { PublicServiceSpecificationAttributesDef } from "@app/@generated";

type ServiceControlsType = {
  data: {
    id: string;
    attributes: PublicServiceSpecificationAttributesDef;
  };
};
const ServiceControls = (props: ServiceControlsType) => {
  const [isSubscribed, setIsSubscribed] = useState<boolean>(
    props?.data?.attributes?.isSubscribedTo || false
  );
  const [isSubscribing, setSubscribedStatus] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const { isAuthenticated } = useAppSelector(state => ({
    isAuthenticated: state.token.isAuthenticated,
  }));
  const { openModal } = useShowModal();
  const handleSubscribe = () => {
    if (!isAuthenticated) {
      openModal(
        modalAction.custom({
          customAction: "login",
        })
      );
      return;
    }
    const { id } = props.data;

    if (!id) return;

    if (isSubscribed) {
      modalConfirm(t, {
        title: t("default.unsubscribeChangesTitle"),
        content: t("default.unsubscribeChangesText"),
        okText: t("default.unsubscribe"),
        onOk: () => {
          setSubscribedStatus(true);
          dispatch(deleteServiceSubscribe({ serviceId: id })).then(() => {
            notification.success(t("default.unsubscribeNotification"), "");
            setIsSubscribed(false);
            setSubscribedStatus(false);
          });
        },
      });
    } else {
      setSubscribedStatus(true);
      dispatch(postServiceSubscribe({ serviceId: id })).then(() => {
        notification.success(t("default.subscribeNotification"), "");
        setIsSubscribed(true);
        setSubscribedStatus(false);
      });
    }
  };
  return (
    <>
      <Permission
        requiredPermissions={[
          PermissionEnum.SERVICES_SUBSCRIBE,
          PermissionEnum.SERVICES_UNSUBSCRIBE,
        ]}
        key="subscribe"
      >
        <Button
          type="primary"
          onClick={handleSubscribe}
          className={styles.button}
          loading={isSubscribing}
        >
          {isSubscribed ? t("default.unsubscribe") : t("default.subscribe")}
        </Button>
      </Permission>
    </>
  );
};

export default ServiceControls;
