import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  PublicServiceSpecificationDef,
  PublicServiceSpecificationsApiServicesGetRequest,
  PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDeleteRequest,
  PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPostRequest,
} from "@app/@generated";

import { ErrorDef } from "@app/types/api.types";

import { servicesApi, servicesSubscriptionsApi } from "../api/services.api";

export const SERVICES_FEATURE_KEY = "services";

interface SliceState {
  services: PublicServiceSpecificationDef[] | [];
  isLoading: boolean;
  isLoadingService: boolean;
  error: ErrorDef | null;
  service?: PublicServiceSpecificationDef | null;
}

const initialState: SliceState = {
  services: [],
  isLoading: false,
  isLoadingService: false,
  error: null,
  service: null,
};

export const getServices = createAsyncThunk(
  "services/getServices",
  async (
    params: PublicServiceSpecificationsApiServicesGetRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await servicesApi.servicesGet({
        ...params,
      });
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const postServiceSubscribe = createAsyncThunk(
  "services/postServiceSubscribe",
  async (
    params: PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPostRequest,
    { rejectWithValue }
  ) => {
    try {
      const response =
        await servicesSubscriptionsApi.servicesServiceIdSubscriptionPost({
          ...params,
        });
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteServiceSubscribe = createAsyncThunk(
  "services/deleteServiceSubscribe",
  async (
    params: PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDeleteRequest,
    { rejectWithValue }
  ) => {
    try {
      const response =
        await servicesSubscriptionsApi.servicesServiceIdSubscriptionDelete({
          ...params,
        });
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const servicesSlice = createSlice({
  name: SERVICES_FEATURE_KEY,
  initialState,
  reducers: {
    clearService: state => {
      state.service = null;
      state.isLoadingService = false;
      state.error = null;
    },
    clearServices: state => {
      state.services = [];
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getServices.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.services = [];
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(getServices.fulfilled, (state, action: any) => {
      state.isLoading = false;
      state.services =
        (action.payload.data as PublicServiceSpecificationDef[]) ?? [];
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(getServices.rejected, (state, action: any) => {
      state.isLoading = false;
      state.error = action.payload as ErrorDef;
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(postServiceSubscribe.fulfilled, (state, action: any) => {
      state.isLoading = false;

      const serviceIndex = state.services.findIndex(
        (service: PublicServiceSpecificationDef) =>
          service.id === action.payload.data.id
      );

      if (serviceIndex) {
        const newServices = [...state.services];
        newServices[serviceIndex].attributes = action.payload.data.attributes;
        state.services = [...newServices];
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(deleteServiceSubscribe.fulfilled, (state, action: any) => {
      state.isLoading = false;
      const serviceIndex = state.services.findIndex(
        (service: PublicServiceSpecificationDef) =>
          service.id === action.payload.data.id
      );
      if (serviceIndex) {
        const newServices = [...state.services];
        newServices[serviceIndex].attributes = action.payload.data.attributes;
        state.services = [...newServices];
      }
    });
  },
});

export const { clearService } = servicesSlice.actions;

export const servicesReducer = servicesSlice.reducer;
