import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
import { METHOD_COLORS } from "@app/components/GraphOperationItem/GraphOperationItem.constants";
import { useState } from "react";
import {
  getLineXPosition,
  getLineYPosition,
} from "@app/components/DiagramWrapper/DiagramWrapper.utils";

import { IMAGE_TRASH_URL } from "@app/constants/common";
import { cutString } from "@app/utils/common";
import { ServiceSpecificationApiOperationLinkPositionEnum } from "@app/@generated";

const GraphOperationItem = ({
  httpMethod,
  path,
  onChangePosition,
  onSavePosition,
  isEditable,
  id,
  onDelete,
  onStartDrawLine,
  isAvalableLines,
  onStopDrawLine,
  xCoordinate = 10,
  yCoordinate = 10,
  width,
  height,
  onHover,
}: {
  xCoordinate?: number;
  yCoordinate?: number;
  httpMethod: string;
  id: string;
  path: string;
  width: number;
  height: number;
  isEditable: boolean;
  onHover: (id: string | null) => void;
  onChangePosition: (id: string, x: number, y: number) => void;
  onSavePosition?: (id: string, x: number, y: number) => void;
  onDelete?: (id: string) => void;
  onStartDrawLine: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evt: any,
    startX: number,
    startY: number,
    id: string,
    position: ServiceSpecificationApiOperationLinkPositionEnum
  ) => void;
  onStopDrawLine: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evt: any,
    startX: number,
    startY: number,
    id: string,
    position: ServiceSpecificationApiOperationLinkPositionEnum
  ) => void;
  isAvalableLines: boolean;
}) => {
  const [isHover, setHoverStatus] = useState<boolean>(false);
  const [imageBin] = useImage(IMAGE_TRASH_URL);

  const handleDelete = () => {
    if (onDelete) onDelete(id);
  };

  const handleStartDrawLine = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evt: any,
    position: ServiceSpecificationApiOperationLinkPositionEnum
  ) => {
    evt.cancelBubble = true;
    onStartDrawLine(
      evt,
      getLineXPosition(width, height, xCoordinate, position),
      getLineYPosition(width, height, yCoordinate, position),
      id,
      position
    );
  };
  const handleStopDrawLine = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evt: any,
    position: ServiceSpecificationApiOperationLinkPositionEnum
  ) => {
    evt.cancelBubble = true;
    onStopDrawLine(evt, xCoordinate + width / 2, yCoordinate, id, position);
  };

  return (
    <Group
      draggable={isEditable}
      width={width}
      height={height}
      x={xCoordinate}
      y={yCoordinate}
      onMouseEnter={() => {
        setHoverStatus(true);
      }}
      onMouseLeave={() => {
        setHoverStatus(false);
      }}
      onDragStart={() => {
        onHover(null);
      }}
      onDragMove={
        isEditable
          ? e => {
              const { x, y } = e.target.position();
              onChangePosition(id, x, y);
            }
          : undefined
      }
      onDragEnd={
        isEditable
          ? e => {
              const { x, y } = e.target.position();
              onChangePosition(id, x, y);
              if (onSavePosition) onSavePosition(id, x, y);
            }
          : undefined
      }
    >
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={httpMethod ? METHOD_COLORS[httpMethod].background : "white"}
        cornerRadius={8}
        stroke={httpMethod ? METHOD_COLORS[httpMethod].border : "black"}
        strokeWidth={1}
      />
      {isAvalableLines && isEditable && (
        <Rect
          x={width / 2 - 4}
          y={-4}
          width={8}
          height={8}
          fill={"white"}
          cornerRadius={0}
          stroke={"black"}
          strokeWidth={1}
          scaleX={isHover ? 1 : 0}
          scaleY={isHover ? 1 : 0}
          onMouseDown={evt =>
            handleStartDrawLine(
              evt,
              ServiceSpecificationApiOperationLinkPositionEnum.TOP
            )
          }
          onMouseUp={evt =>
            handleStopDrawLine(
              evt,
              ServiceSpecificationApiOperationLinkPositionEnum.TOP
            )
          }
        />
      )}
      {isAvalableLines && isEditable && (
        <Rect
          x={width - 4}
          y={height / 2 - 4}
          width={8}
          height={8}
          fill={"white"}
          cornerRadius={0}
          stroke={"black"}
          strokeWidth={1}
          scaleX={isHover ? 1 : 0}
          scaleY={isHover ? 1 : 0}
          onMouseDown={evt =>
            handleStartDrawLine(
              evt,
              ServiceSpecificationApiOperationLinkPositionEnum.RIGHT
            )
          }
          onMouseUp={evt =>
            handleStopDrawLine(
              evt,
              ServiceSpecificationApiOperationLinkPositionEnum.RIGHT
            )
          }
        />
      )}
      {isAvalableLines && isEditable && (
        <Rect
          x={-4}
          y={height / 2 - 4}
          width={8}
          height={8}
          fill={"white"}
          cornerRadius={0}
          stroke={"black"}
          strokeWidth={1}
          scaleX={isHover ? 1 : 0}
          scaleY={isHover ? 1 : 0}
          onMouseDown={evt =>
            handleStartDrawLine(
              evt,
              ServiceSpecificationApiOperationLinkPositionEnum.LEFT
            )
          }
          onMouseUp={evt =>
            handleStopDrawLine(
              evt,
              ServiceSpecificationApiOperationLinkPositionEnum.LEFT
            )
          }
        />
      )}
      {isAvalableLines && isEditable && (
        <Rect
          x={width / 2 - 4}
          y={height - 4}
          width={8}
          height={8}
          fill={"white"}
          cornerRadius={0}
          stroke={"black"}
          strokeWidth={1}
          scaleX={isHover ? 1 : 0}
          scaleY={isHover ? 1 : 0}
          onMouseDown={evt =>
            handleStartDrawLine(
              evt,
              ServiceSpecificationApiOperationLinkPositionEnum.BOTTOM
            )
          }
          onMouseUp={evt =>
            handleStopDrawLine(
              evt,
              ServiceSpecificationApiOperationLinkPositionEnum.BOTTOM
            )
          }
        />
      )}
      <Group
        onMouseEnter={() => {
          onHover(id);
        }}
        onMouseLeave={() => {
          onHover(null);
        }}
      >
        <Text
          text={httpMethod}
          x={15}
          y={15}
          fontSize={14}
          fontFamily={"'Source Code Pro', monospace"}
          fontStyle="bold"
          color="#3b4151"
        />
        <Text
          text={cutString(path)}
          x={25 + httpMethod.length * 8}
          y={15}
          fontSize={14}
          fontFamily={"'Source Code Pro', monospace"}
          color="#3b4151"
        />
      </Group>
      {isHover && imageBin && isEditable && (
        <Image
          image={imageBin}
          x={width - 12}
          y={-6}
          width={16}
          height={16}
          onClick={handleDelete}
        />
      )}
    </Group>
  );
};

export default GraphOperationItem;
