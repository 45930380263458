import { notification } from "antd";
import notificationTheme from "@app/theme/notification.theme";

export type NotificationType = {
  title: string;
  message: string;
  duration?: number;
  type?: string;
};
const useNotification = () => {
  const notificationOpen = ({
    title,
    type,
    duration,
    message,
  }: NotificationType) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    notification[type]({
      message: title,
      description: message,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style: notificationTheme[type],
      duration: duration || 1,
    });
  const warning = (title: string, message: string, duration?: number) =>
    notificationOpen({
      duration: duration || 3,
      title,
      message,
      type: "warning",
    });
  const success = (title: string, message: string, duration?: number) =>
    notificationOpen({
      duration: duration || 3,
      title,
      message,
      type: "success",
    });
  const error = (title: string, message: string, duration?: number) =>
    notificationOpen({
      duration: duration || 2,
      title,
      message,
      type: "error",
    });

  return { success, warning, error };
};

export default useNotification;
