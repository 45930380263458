import { useState } from "react";

import { Tabs, Layout } from "antd";

import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";

import EmptyState from "@app/components/EmptyState/EmptyState";
import ServicesSidebar from "@app/components/Common/molecules/ServicesSidebar/ServicesSidebar";
import Breadcrumbs from "@app/components/Common/molecules/Breadcrumbs/Breadcrumbs";
import DeleteConfirmationModal, {
  DeletePayloadDef,
} from "@app/components/DeleteConfirmationModal/DeleteConfirmationModal";
import { serviceSpecificationsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";
import useNotification from "@app/hooks/useNotification";
import { getServiceSpecifications } from "@app/features/services-specifications";
import { useAppDispatch } from "@app/store/store";
import { useTranslation } from "react-i18next";
import InfoPreview from "@app/components/InfoPreview";
import { Pane, Panes } from "@app/types/common.types";

const { TabPane } = Tabs;
const { Content } = Layout;

const initialPanes: Array<Pane> = [
  {
    panTitle: "Info",
    content: <InfoPreview />,
    key: "0",
    closable: false,
    component: null,
    title: "",
  },
];

const DEFAULT_ACTIVE_TAB_KEY = "0";

type ServicesListPropsType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  services: any;
  allPanes: Panes;
  title: string;
};

function ServicesList({ services, title, allPanes }: ServicesListPropsType) {
  const [panes, setPanes] = useState<Array<Pane>>(initialPanes);

  const [activeKey, setActiveKey] = useState(DEFAULT_ACTIVE_TAB_KEY);
  const [activePan, setActivePan] = useState<Pane>({} as Pane);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const remove = (targetKey: string) => {
    const newPanes = panes.filter(pane => pane.key !== targetKey);
    setActiveKey(DEFAULT_ACTIVE_TAB_KEY);
    setPanes(newPanes);
  };

  const add = (key: string) => {
    const inPanes = panes.find(item => item.key === key);
    const type: string = key.split("-/-")[1];
    const id: string = key.split("-/-")[0];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const service = services.filter((service: any) => service.id === id)[0];

    if (!inPanes && type) {
      const activePanNew: Pane = {
        ...allPanes[type],
        key: key,
        panTitle: allPanes[type].panTitle(service.attributes?.title),
        title: service.attributes?.title,
        data: { attributes: { ...service.attributes }, id: service.id },
      };
      //temperary remove previos tabs
      setPanes([...initialPanes, activePanNew]);
      //setPanes([...panes, activePanNew]);
      setActivePan(activePanNew);
      setActiveKey(key);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEditTab = (item: any) => {
    remove(item);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectMenu = (item: any) => {
    setActiveKey(item.key);
    add(item.key);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTabChange = (active: any) => {
    setActiveKey(active);
  };

  const handleDelete = async (data: DeletePayloadDef, callback: () => void) => {
    const { id } = data;

    if (!id) return;

    try {
      await serviceSpecificationsApi.serviceSpecificationsServiceSpecificationIdDelete(
        {
          serviceSpecificationId: id,
        }
      );
      notification.success(
        t("serviceSpecifications.deleteNotificationTitle"),
        t("serviceSpecifications.deleteNotification")
      );
      dispatch(getServiceSpecifications({}));
      setActiveKey(DEFAULT_ACTIVE_TAB_KEY);
      setActivePan({});
      setPanes(initialPanes);
      callback();
    } catch (error: unknown) {
      notification.error(
        t("default.error"),
        t("serviceSpecifications.deleteErrorNotification")
      );
    }
  };

  return (
    <ContentLayout
      noContentStyle
      sidebar={
        services.length !== 0 ? (
          <ServicesSidebar
            services={services}
            onSelectMenu={handleSelectMenu}
          />
        ) : null
      }
    >
      {services.length === 0 && <EmptyState />}
      {services.length !== 0 && (
        <>
          {activeKey !== DEFAULT_ACTIVE_TAB_KEY && (
            <div
              style={{
                padding: "5px 24px",
                margin: "0 24px 24px 0",
                background: "#fff",
              }}
            >
              <Breadcrumbs
                items={[
                  {
                    breadcrumbName: title,
                  },
                  {
                    breadcrumbName: activePan.title || "",
                  },
                  {
                    breadcrumbName: activePan.type || "",
                  },
                ]}
              />
            </div>
          )}
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: "0 24px 0 0",
              minHeight: 280,
              background: "#fff",
            }}
          >
            <Tabs
              activeKey={activeKey}
              onEdit={handleEditTab}
              onChange={handleTabChange}
            >
              {panes.map((pane: Pane) => {
                const Component = pane.component ? pane.component : null;
                return (
                  <TabPane
                    tab={pane.panTitle}
                    key={pane.key}
                    closable={pane.closable}
                  >
                    {pane.content}
                    {pane.component && pane.data && (
                      <Component data={pane.data} />
                    )}
                  </TabPane>
                );
              })}
            </Tabs>
          </Content>
        </>
      )}
      <DeleteConfirmationModal onConfirm={handleDelete} />
    </ContentLayout>
  );
}

export default ServicesList;
